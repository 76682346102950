import React from 'react';
import './styles.css';

function Header() {
    return (
        <header className='home-header'>
            <h1>
                <span>“</span> Blog <span>”</span>
            </h1>
            <p>
                Awesome place to make oneself <br /> productive and entertained through
                daily updates.
            </p>
        </header>
    );
}

export default Header