import React from 'react';
import './CSS/QuoteCard.css';

function QuoteCard(props) {
    const quote = props.quote;
    const newQuote = quote.split('\n').map(str => <p key={str.length}>{str}</p>);
    return (

        <div className="qoute-container">

            <div className="card text-center">

                <div className="card-body px-5 center">

                    <i className="fa-solid fa-quote-left" />

                    <div className='card-text py-5'>{newQuote}</div>

                    <div className="divider mb-3"></div>
                    <h5>{props.userName}</h5>
                    <p>{props.role}</p>

                </div>



            </div>
        </div>



    )
}

export default QuoteCard