import React from 'react';
import './styles.css'

function Divider() {
    return (
        <div className="wrapper">
            <div class="divider div-transparent div-dot"></div>
        </div>

    )
}

export default Divider