import React from 'react'
import './CSS/heroSection.css';
import './CSS/app.css';
import { Button } from './Button';

function HeroSection() {
    return (
        <div className='hero-container' id='home'>
            {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
            <h1>Learn Data Science</h1>
            <p>What are you waiting for?</p>
            <div className='hero-btns'>
                <Button
                    linkTo={'#courses'}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                    EXPLORE
                </Button>
                <Button
                    linkTo={'#contact-us'}
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    
                >
                    CONTACT US
                </Button>
            </div>
        </div>
    )
}

export default HeroSection

