import React from 'react';
import './CSS/Footer.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <div className='footer-container'>

      <div className="contactUs text-center">
        <h6>Get In Touch</h6>
        <i className="fas fa-envelope"></i>
        <p><a href="mailto:durgabiotech14@gmail.com">durgabiotech14@gmail.com</a></p>
        <i className="fas fa-mobile-alt"></i>
        <p><a href='tel:+917755910537'>+91 7755910537</a></p>
      </div>

      <section className='social-media'>


        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <HashLink to='#home' className='social-logo'>
              Gupta Classes
              <i className='fab fa-typo3' />
            </HashLink>
          </div>
          <small className='website-rights'>Gupta Classes © 2016</small>
          <div className='social-icons'>
            <a
              className='social-icon-link facebook'
              href='https://www.facebook.com/SAS-and-python-class-385422712030277/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            {/* <a
              className='social-icon-link instagram'
              href='https://www.instagram.com/durga.gupta.12/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a> */}
            {/* <a
              className='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </a> */}
            <a
              className='social-icon-link twitter'
              href='https://twitter.com/DurgaPr95109012'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </a>
            <a
              className='social-icon-link twitter'
              href='https://www.linkedin.com/in/durga-prasad-69b69417/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;