import React from 'react';
import QuotesSection from './QuotesSection';
import SkillsSection from './SkillsSection';
import ToolsSection from './ToolsSection';
import Footer from './Footer';
import HeroSection from './HeroSection';
import './CSS/Home.css';
import FAQSection from './FAQSection';
import AboutCoachSection from './AboutCoachSection';
import ContactUsSection from './ContactUsSection';


const Home = () => {
    return (
        <>
            <div className="home-container">
                <HeroSection />
                <SkillsSection />
                <ToolsSection />
                <QuotesSection />
                <FAQSection />
                <AboutCoachSection />
                <ContactUsSection />
                <Footer />

            </div>



        </>
    )
}

export default Home;