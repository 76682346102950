import React, { useState } from 'react';
import './CSS/ContactUsSection.css';
import image from '../assets/images/form-background-image.svg';
import firestore from '../firebase';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function ContactUsSection() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [error_message, setErrorMsg] = useState("");

    const handleSubmit = async event => {
        // 👇️ prevent page refresh
        event.preventDefault();
        var text;
        if (name.length < 5) {
            text = "Please Enter valid Name";
            setErrorMsg(text);
            return false;
        }
        if (subject.length < 2) {
            text = "Please Enter Correct Subject";
            setErrorMsg(text);
            return false;
        }
        if (isNaN(phone) || phone.length !== 10) {
            text = "Please Enter valid Phone Number";
            setErrorMsg(text);
            return false;
        }
        if (email.indexOf("@") === -1 || email.length < 6) {
            text = "Please Enter valid Email";
            setErrorMsg(text);
            return false;
        }
        if (message.length < 1) {
            text = "Please Enter Message";
            setErrorMsg(text);
            return false;
        }

        try {
            await addDoc(collection(firestore, "formData"), {
                name: name,
                subject: subject,
                phone: phone,
                email: email,
                message: message,
                timestamp: serverTimestamp()

            });
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setSubject('');
            toast.success("Form Submitted Successfully!");
            console.log('form submitted ✅');
        } catch (e) {
            console.error("Error adding document: ", e);
            toast.error("Error Submitting, Try Again.");
        }





    };


    return (
        <div className='container-fluid contact-us-body mb-5' id="contact-us">

            <div className="row">

                <div className="form-container-background col-10 mx-auto">

                    <div className="col-11 form-container mx-auto">

                        <div className="row my-5">


                            <div className="form-image-container col-md-4 col-10 mx-auto ">

                                <img src={image} alt="" className='form-image' />

                            </div>

                            <div className="wrapper py-5 contact-us-form col-md-6 col-10 mx-auto">

                                <h2>Contact us</h2>
                                <div id="error_message"><p className={error_message.length >= 1 ? 'p-2' : ''}>{error_message}</p></div>
                                <form id="myform" onSubmit={handleSubmit}>
                                    <div className="input_field">
                                        <input type="text" placeholder="Name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="input_field">
                                        <input type="text" placeholder="Subject" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                    <div className="input_field">
                                        <input type="text" placeholder="Phone" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                    <div className="input_field">
                                        <input type="text" placeholder="Email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="input_field">
                                        <textarea placeholder="Message" id="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>
                                    <div className="btn">
                                        <input type="submit" />
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </div>
    )
}

export default ContactUsSection