import React from 'react';
import ToolCard from './ToolCard';
import './CSS/ToolsSection.css';

import logo_python from '../assets/images/img-python.png';
import  logo_power_bi from '../assets/images/img-power-bi.png';
import logo_sas from '../assets/images/img-sas.png';
import logo_sql from '../assets/images/img-sql.png';
import logo_r from '../assets/images/logo-r.png';
import logo_tableau from '../assets/images/img-tableau.jpg';



const skills = [

    {
        imageSrc: logo_r,
        label: "R Tool",
    },
    {
        imageSrc: logo_python,
        label: "Python",
    },
    {
        imageSrc: logo_tableau,
        label: "Tableau",
    },
    {
        imageSrc: logo_sas,
        label: "SAS",
    },
    {
        imageSrc: logo_sql,
        label: "MySQL",
    },
    {
        imageSrc: logo_power_bi,
        label: "Power BI",
    },
   




]


function ToolsSection() {
    return (
        <div className='cards' id="courses">

            <div className=" py-5 ">
                <h1 className='text-center'>Tools and Techonology</h1>
            </div>

            <div className="container-fluid pb-5">
                <div className="row">
                    <div className="col-11 mx-auto">
                        <div className="row">

                            {skills.map((val, index) => {
                                return <ToolCard
                                    src={val.imageSrc}
                                    label={val.label}
                                    key={index}
                                />



                            })}

                        </div>
                    </div>


                </div>


            </div>

        </div>
    )
}

export default ToolsSection


