import React from 'react';
import './CSS/AboutCoachSection.css';
import profileImage from '../assets/images/coach-profile-image.jpg';


function AboutCoachSection() {
    return (
        <div className='container-fluid about-coach-content' id="about">

            <div className="row">

                <div className="col-10 mx-auto">

                    <div className="row my-5 ">

                        <div className="coach-image-container col-md-4 col-10 mx-auto ">



                            {/* <div className='image-bakground'></div> */}

                            <img className='coach-profile-image' src={profileImage} alt="coach profile" />
                        </div>

                        <div className="about-coach-text col-md-6 col-10 mx-auto ">

                            <h2>About the Coach</h2>
                            <p>Dp gupta sir having 14 years experience in data science .He had worked in many mnc companies like infosys tcs etc</p>
                        </div>


                    </div>

                </div>
            </div>



        </div>
    )
}

export default AboutCoachSection