import React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle';

import './CSS/app.css';
import NavBar2 from './NavBar2';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import BlogHome from '../pages/blog_home/BlogHome';
import Blog from '../pages/blog/Blog';
import { ToastContainer } from 'react-toastify';

const App = () => {
    return (
        <>
            <BrowserRouter>
                <NavBar2 />
                <ToastContainer/>
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path='/blog' exact element={<BlogHome />} />
                    <Route path="/blog/:id" exact element={<Blog />} />
                </Routes>

            </BrowserRouter>

        </>
    )
}

export default App;