import React from 'react';
import './CSS/SkillSection.css';


function SkillCard(props) {
  return (
    <>
      <div  className="col-md-3 col-sm-6 col-12 mx-auto m-2">
        <div className="card" >
          <img src={props.src} className="card-img-top" alt="Course Logo" />
          <div className="card-body text-center">
            <p className="card-text">{props.label}</p>
          </div>
        </div>
      </div>

    </>
  );
}



export default SkillCard