import React from 'react';
import './CSS/FAQSection.css';
import QuestionAnswerItem from './QuestionAnswerItem';


const faqs = [

    {
        question: "Who's this class for?",
        answer: 'This virtual class is designed for professionals looking to make a change in their leadership style and build a productive, happy, autonomous team. Individuals attending this class are open-minded and ready to identify ways you can adjust your leadership style to start getting results.'
    },
    {
        question: "What's the time commitment?",
        answer: "The class itself is 4 hours, broken into four 45-minute sessions with 15 minute breaks. When you sign up for the training, you'll also gain lifetime access to all the downloadable resources and recorded video trainings. This way, you can revisit the program at your own pace and a your own convenience."
    },
    {
        question: "Am I guaranteed a promotion?",
        answer: "While we'd love to be able to guarantee a promotion, we aren't fortune tellers. What we can guarantee is that you'll be given the tools, techniques, and support you need to clarify what you want, articulate your worth, and lead a team strategically. "
    },
    {
        question: "Do you offer 1:1 Coaching?",
        answer: "YES! I recommend first taking my free virtual group class—most attendees get a lot of value from it. From there we can identify your short or long-term goals and work towards achieving them together."
    },

]

function FAQSection() {
    return (
        <section>
            <div className='faq-content-body'>

                <div className="col-10 mx-auto py-5">

                    <h2 className='text-center'>Have Questions? We Have Answers.</h2>


                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10 mx-auto">
                            <div className="row">
                                {faqs.map((value, index) => {
                                    return <QuestionAnswerItem
                                        question={value.question}
                                        answer={value.answer}
                                        key= {index}
                                    />
                                })}

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>

    )
}

export default FAQSection