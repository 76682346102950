import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '../../../common/Chip/Chip';
import './styles.css';

const BlogItem = ({
  blog
}) => {
  console.log('last update', blog.lastUpdate.toDate().toLocaleString());

  return (

    <div className="col-md-5 col-lg-4 col-10 m-2 mx-auto">

      <Link className='blogItem-link' to={`/blog/${blog.blogId}`}>
        <div className='blogItem-wrap card'>
          <div className='img-container'>
            <img className='blogItem-cover' src={blog.featureImage ? blog.featureImage : '/assets/images/No_image_available.svg.webp'} alt='cover' />
          </div>

          <div className="card-body">
            <div className='row'>{blog.categories ? blog.categories.map((val, index) => <Chip key={index} label={val} />) : null}</div>
            <h3>{blog.title}</h3>
            <p className='blogItem-desc'>{blog.excerpt}</p>
            <footer>
              <div className='blogItem-author'>
                <img src={blog.author.profileImage ? blog.author.profileImage : '/assets/images/No_image_available.svg.webp'} alt='avatar' />
                <div>
                  <h6>{blog.author.name}</h6>
                  <p>{blog.lastUpdate.toDate().toLocaleString()}</p>
                </div>
              </div>
              <Link className='blogItem-link' to={`/blog/${blog.blogId}`}>
                ➝
              </Link>
            </footer>
          </div>

        </div>
      </Link>
    </div>
  );
};

export default BlogItem;