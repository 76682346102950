import React from 'react';
import Header from '../../components/blog_home/header/Header';
import SearchBar from '../../components/blog_home/search_bar/SearchBar';
import BlogList from '../../components/blog_home/blog_list/BlogList';
import { blogList } from '../../config/data';
import { useState } from 'react';


function BlogHome() {
    const [blogsMetadata, setBlogsMetaData] = useState(blogList);

    const [searchKey, setSearchKey] = useState("");


    // Handle Submit 
    const handleSearchSubmit = event => {
        console.log("Called");
        event.preventDefault();
        handleSearchResults();
    }

    // Search for blogs by category
    const handleSearchResults = () => {
        console.log("Called");
        console.log(searchKey);
        const allBlogs = blogsMetadata;
        const filteredBlogs = allBlogs.filter(blog => blog.category.toLowerCase().includes(searchKey.toLowerCase().trim()));

        setBlogsMetaData(filteredBlogs);

    }

    const handleClearSearch = () => {
        setBlogsMetaData(blogList);
        setSearchKey('');

    }

    return (
        <div>
            {/* Page Header */}
            <Header />

            {/*Search Bar*/}
            {/* <SearchBar value={searchKey} formSubmit={handleSearchSubmit} handleSearchKey={e => setSearchKey(e.target.value)}
                clearSearch={handleClearSearch} /> */}

            {/* Blog List and Empty List */}
            <BlogList blogs={blogsMetadata} />

        </div>
    )
}

export default BlogHome