import React from 'react';
import QuoteCard from './QuoteCard';
import './CSS/QuotesSection.css';

const qoutesData = [
    {
        id: '1',
        quote: "The teaching faculty is quite good. One of the few institutes that teach data science in Python as well as R, is the additional benefit. They give us access to their webinars(which are quite helpful in future) and pre-recorded lectures along with the live sessions if we miss hem. The assignment team supports us throughout the completion of assignmens. lt is worth the money.",
        userName: 'Saumya Kumar',
        role: 'Tech Lead'
    },

    {
        id: '2',
        quote: "I have attended the data science training over here and must say the trainer and the way they each theory alongwith practical lessons have helped me a lot in learning and understanding data science. i recommend for all those who would like to begin or switch their career to data science. Provides us with variety of study materials and examples which helps us a lot in understanding the topic in a better way.",
        userName: 'Shivendra Kumar',
        role: 'Data Analyst'
    },
    {
        id: '3',
        quote: "I attend Data Science course and don't hesitate to rate the training quality is really good. Trainers are awesome and the support from the assignments team mentors is really good.\n Great training insitute for Data Science course\nNice lnstitute for Data Science training...\nData Scienceourse training is good.",
        userName: 'Ashish Kumar',
        role: 'Datascience Engineer'
    },

    {
        id: '4',
        quote: "The experience of learning data science from ExcelR was great. Best place to learn data science if you don't have any previous knowledge about it. All the concepts were well explained in depth and very easy to understand. The faulty are ell experienced and have good knowledge about the subject. Study material and video references given in every course that came very handy. Greal experience in this institute will deflnitely suggest others",
        userName: 'Abhimanu Kumar',
        role: 'Data Analyst'
    },


]




function QuotesSection() {
    return (
        <>
            <section>

                <div className="quotes-header col-sm-11 col-md-9 col-lg-6 mx-auto text-center pt-2 pb-5 mb-5">
                    <div className='py-5'>
                        <i className="fa-sharp fa-solid fa-circle"></i>
                        <i className="fa-sharp fa-solid fa-circle"></i>
                        <i className="fa-sharp fa-solid fa-circle"></i>
                        <i className="fa-sharp fa-solid fa-circle"></i>
                        <i className="fa-sharp fa-solid fa-circle"></i>
                    </div>
                    <h2>Words of praise from past attendees</h2>
                </div>

                <div className="quotes-container mb-5">
                    <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="cards-wrapper">
                                    <QuoteCard
                                        id={qoutesData[0].id}
                                        quote={qoutesData[0].quote}
                                        userName={qoutesData[0].userName}
                                        role={qoutesData[0].role}
                                    />

                                    <div className='d-none d-md-block'>
                                        <QuoteCard
                                    id={qoutesData[1].id}
                                            quote={qoutesData[1].quote}
                                            userName={qoutesData[1].userName}
                                            role={qoutesData[1].role}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="carousel-item">
                                <div className="cards-wrapper">
                                    <QuoteCard
                                        id={qoutesData[2].id}
                                        quote={qoutesData[2].quote}
                                        userName={qoutesData[2].userName}
                                        role={qoutesData[2].role}
                                    />

                                    <div className='d-none d-md-block'>
                                        <QuoteCard
                                            id={qoutesData[3].id}
                                            quote={qoutesData[3].quote}
                                            userName={qoutesData[3].userName}
                                            role={qoutesData[3].role}
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>




            </section>

        </>
    )
}

export default QuotesSection

