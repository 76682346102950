import React from 'react';
import './CSS/FAQSection.css'

function QuestionAnswerItem(props) {
    return (
        <>
            <div className='col-md-5 col-10 m-2 mx-auto '>
                <h4>{props.question}</h4>
                <p className='answer-text'>{props.answer}</p>
            </div>

        </>



    )
}

export default QuestionAnswerItem