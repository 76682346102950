import React from 'react';
import CardItem from './SkillCard';
import './CSS/SkillSection.css';

import img_statistics from '../assets/images/img-statistics.jpg';
import img_data_analyst from '../assets/images/img-data-analysis.jpg';
import img_data_visualisation from '../assets/images/img-data-visual.jpg';
import img_ensemble from '../assets/images/img-ensemble.jpg';
import img_forecasting from '../assets/images/img-forecast.png';
import img_machine_learning from '../assets/images/img-machine-learning.jpg';
import img_text_mining from '../assets/images/text-mining.png';

import img_hypothesis from '../assets/images/img-hypo.png';


const skills = [

    {
        imageSrc: img_statistics,
        label: "Statics",
    },
    {
        imageSrc: img_data_analyst,
        label: "Data Analyst/Business Analyst",
    },
    {
        imageSrc: img_data_visualisation,
        label: "Data Visualisation",
    },
    {
        imageSrc: img_machine_learning,
        label: "Machine Learning Algorithms",
    },
    {
        imageSrc: img_ensemble,
        label: "Ensemble Techinique",
    },
    {
        imageSrc: img_text_mining,
        label: "Text Mining/Natural Language Processing",
    },
    {
        imageSrc: img_forecasting,
        label: "Forecasting Analytics",
    },
    {
        imageSrc: img_hypothesis,
        label: "Hypothesis Testing",
    },




]


function SkillSection() {
    return (
        <div className='cards' id="courses">

            <div className=" py-5 ">
                <h1 className='text-center'>Skills Covered</h1>
            </div>

            <div className="container-fluid pb-5">
                <div className="row">
                    <div className="col-11 mx-auto">
                        <div className="row">

                            {skills.map((val, index) => {
                                return <CardItem
                                    src={val.imageSrc}
                                    label={val.label}
                                    key={index}
                                />



                            })}

                        </div>
                    </div>


                </div>


            </div>

        </div>
    )
}

export default SkillSection


// const courseData = [

//     {
//         imageSrc: img_sas,
//         label: "SAS",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_power_bi,
//         label: "Power BI",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_tablue,
//         label: "Tableue",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_python,
//         label: "Python",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_python,
//         label: "R",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_python,
//         label: "ADAM",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_sas2,
//         label: "SDTM",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_sql,
//         label: "SQL",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },
//     {
//         imageSrc: img_sql,
//         label: "SQL",
//         path: '/courses',
//         text: 'Some quick example text to build on the card title and make up the bulk of the card content.'
//     },



// ]