import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Chip from '../../components/common/Chip/Chip';
import EmptyList from '../../components/common/empty_list/EmptyList';
import './styles.css';
import { Link } from 'react-router-dom';

import Blocks from 'editorjs-blocks-react-renderer';

import firestore from '../../firebase'

import { doc, getDoc } from "firebase/firestore";
import Divider from '../../components/common/divider/Divider';



const Blog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);


  const fetchBlogById = async (id) => {
    console.log('fetching blog ', id);

    const docRef = doc(firestore, 'blog', id);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('blog exists ', docSnap.data());
      return docSnap.data();
    } else {
      console.log('no blog with', id);
      return null;
    }

  }

  useEffect(() => {

    fetchBlogById(id).then((blog) => {
      if (blog == null) {
        console.log("no blog show 404");
        setBlog(null);
      } else {
        console.log("Have blog", blog);
        setBlog(blog);

      }
    });

  }, [id]);


  const config = {
    code: {
      className: "language-js"
    },
    delimiter: {
      className: "border border-2 w-16 mx-auto"
    },
    embed: {
      className: "border-0"
    },
    header: {
      className: "text-3xl font-bold"
    },
    image: {
      className: "w-full max-w-screen-md",
      actionsClassNames: {
        stretched: "w-full h-80 object-cover",
        withBorder: "border border-2",
        withBackground: "p-2",
      }
    },
    list: {
      className: "list-inside"
    },
    paragraph: {
      className: "text-base text-opacity-75",
      actionsClassNames: {
        alignment: "text-center", // This is a substitution placeholder: left or center.
      }
    },
    quote: {
      className: "py-3 px-5 italic font-serif"
    },
    table: {
      className: "table-auto"
    }
  };

  console.log('incoming blog', blog);

  return (
    <>
      <Link className='blog-goBack' to='/blog'>
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
      {blog ? (
        <div className='blog-wrap col-md-8 col-lg-6 col-10 m-2 mx-auto'>
          <header>
            <p className='blog-date'>Update {blog.createdOn.toDate().toLocaleString()}</p>

            <div className='blog-subCategory'>
              {blog.categories.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div>
          </header>
          {/* <h1>{blog.title}</h1>
          <img src={blog.cover} alt='cover' />
          <p className='blog-desc'>{blog.description}</p> */}

          <section>
            <h1>{blog.title}</h1>
            <img src={blog.featureImage} alt='cover' />

            <div className='blogItem-author'>

            </div>

            <div className='blogItem-author my-3'>
              <img src={blog.author.profileImage ? blog.author.profileImage : '/assets/images/No_image_available.svg.webp'} alt='avatar' />
              <div className='mt-3'>
                <h6>{blog.author.name}</h6>
                <p>{blog.updatedOn.toDate().toLocaleString()}</p>
              </div>
            </div>

            <Blocks data={blog.content} config={config} />


          </section>

          <Divider />
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default Blog;