import React from 'react';
import './CSS/ToolsSection.css';


function ToolCard(props) {
  return (
    <>
      <div  className="col-md-2 col-sm-3 col-6 mx-auto m-2">
        <div className="card " >
          <img src={props.src} className="tool-card-img-top" alt="Course Logo" />
          <div className="tool-card-body text-center">
            <p className="tool-card-text">{props.label}</p>
          </div>
        </div>
      </div>

    </>
  );
}



export default ToolCard